import { FC } from 'react'
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, Modal, Loader, Navbar, OverflowMenu } from '@aurecon-creative-technologies/styleguide'

import SwipEngageLogo from '../../assets/swipengage-logo.svg?react'

import { AdminFooter } from './AdminFooter'
import { NavbarSubTab, MainModal, NavbarTab, SurveyListPageIndex, CurrentSurvey } from '../../store/AdminStore'

import NavbarTabEnum from '../../enums/NavbarTabEnum'
import NavbarSubTabEnum from '../../enums/NavbarSubTabEnum'
import { config } from '../../config/config'
import TermsCookiesModal from '../TermsCookiesModal'

import Style from '../../styles/layout/AdminLayout.module.sass'

const helpMenu = [
  {
    id: '3',
    label: 'Support Hub',
    newWindow: true,
    url: `https://aurecondigital.atlassian.net/servicedesk/customer/portal/20`,
  },
]

interface IAdminLayoutProps {
  modalText?: string
  disableDesignTab?: boolean
  disableAnalyseTab?: boolean
  children?: React.ReactNode
}

const AdminLayout: FC<IAdminLayoutProps> = (props) => {
  const { user, logout } = useAuth0()
  const [navbarTab, setNavbarTab] = useRecoilState(NavbarTab)
  const [navbarSubTab, setNavbarSubTab] = useRecoilState(NavbarSubTab)
  const setSurveyListPageIndex = useSetRecoilState(SurveyListPageIndex)
  const currentSurvey = useRecoilValueLoadable(CurrentSurvey)
  const [mainModal, setMainModal] = useRecoilState(MainModal)
  const { disableDesignTab, disableAnalyseTab } = props

  const handleHelpIcon = (item: string | number) => {
    const menuItem = helpMenu.find((m) => m.id === item)
    if (menuItem && menuItem.newWindow) window.open(menuItem.url)
    if (menuItem && !menuItem.newWindow) window.location.hash = menuItem.url
  }

  const handleLogout = () => {
    const returnTo = `${config.AUTH0_LOGOUT_RETURN}/#/login`
    logout({ logoutParams: { returnTo } })
  }

  const handleUsername = () => {
    window.location.hash = '/profile'
  }

  const handleMainMenu = (menuItem: number) => {
    setNavbarTab(menuItem)
    setNavbarSubTab(null)
    setSurveyListPageIndex(1)

    if (menuItem === NavbarTabEnum.MY_SURVEYS) window.location.hash = `/surveys`
    if (menuItem === NavbarTabEnum.SHARED_SURVEYS) window.location.hash = `/surveys`
  }

  const handleSubMenu = (menuItem: number) => {
    if (currentSurvey.state !== 'hasValue' && currentSurvey.contents) return

    const survey = currentSurvey.contents

    if (menuItem === NavbarSubTabEnum.PREVIEW) {
      window.open('/#/' + survey.link)
      return
    }

    setNavbarSubTab(menuItem)

    if (menuItem === NavbarSubTabEnum.INFO && navbarSubTab !== NavbarSubTabEnum.INFO)
      window.location.hash = `/editSurvey/${survey.id}`
    if (menuItem === NavbarSubTabEnum.DESIGN) window.location.hash = `/survey/${survey.id}`
    if (menuItem === NavbarSubTabEnum.RESULTS) window.location.hash = `/surveyReport/${survey.id}`
  }

  return (
    <div className={Style.adminLayout}>
      <Navbar
        logo={<SwipEngageLogo className={Style.headerLogo} onClick={() => handleMainMenu(NavbarTabEnum.MY_SURVEYS)} />}
        isDark
        accountActions={[
          <Button key='username' label={user?.name} type='text' cssClass='navbar-username' onClick={handleUsername} />,
          <OverflowMenu
            key='help-button'
            size='extra large'
            items={helpMenu}
            icon='help'
            onSelectItem={(item) => handleHelpIcon(item.id)}
          />,
          <Button key='logout' type='text' label='Logout' onClick={handleLogout} />,
        ]}
      >
        <Button
          type='text'
          label='My Surveys'
          cssClass={navbarTab === NavbarTabEnum.MY_SURVEYS ? 'is-active' : 'is-inactive'}
          onClick={() => handleMainMenu(NavbarTabEnum.MY_SURVEYS)}
        />
        <Button
          type='text'
          label='Shared Surveys'
          cssClass={navbarTab === NavbarTabEnum.SHARED_SURVEYS ? 'is-active' : 'is-inactive'}
          onClick={() => handleMainMenu(NavbarTabEnum.SHARED_SURVEYS)}
        />
      </Navbar>
      {navbarSubTab && (
        <div className='submenu'>
          <Button
            type='text'
            label='Survey Info'
            cssClass={navbarSubTab === NavbarSubTabEnum.INFO ? 'is-active' : 'is-inactive'}
            onClick={() => handleSubMenu(NavbarSubTabEnum.INFO)}
          />
          <Button
            type='text'
            label='Design Survey'
            cssClass={navbarSubTab === NavbarSubTabEnum.DESIGN ? 'is-active' : 'is-inactive'}
            onClick={() => handleSubMenu(NavbarSubTabEnum.DESIGN)}
            disabled={disableDesignTab}
          />
          <Button
            type='text'
            label='Analyse Results'
            cssClass={navbarSubTab === NavbarSubTabEnum.RESULTS ? 'is-active' : 'is-inactive'}
            onClick={() => handleSubMenu(NavbarSubTabEnum.RESULTS)}
            disabled={disableAnalyseTab}
          />
          <Button
            type='text'
            label='Preview'
            cssClass='is-inactive'
            onClick={() => handleSubMenu(NavbarSubTabEnum.PREVIEW)}
          />
        </div>
      )}
      <div>{props.children}</div>
      <Modal
        isShowing={!!mainModal}
        onClose={() => setMainModal(null)}
        cssClass={mainModal?.loader ? Style.modalCenter : ''}
      >
        {mainModal?.loader && <Loader />}
        <h3>{mainModal?.title}</h3>
        <p>{mainModal?.text}</p>
      </Modal>
      <TermsCookiesModal />
      <AdminFooter />
    </div>
  )
}

export default AdminLayout
