import { FC } from 'react'
import { Checkbox } from '@aurecon-creative-technologies/styleguide'

import AnswerTypeEnum from '../../enums/AnswerTypeEnum'
import { IQuestionResponse, ISurveyQuestion } from '../../api/model'

import Header from '../layout/Header'
import Footer from '../layout/Footer'
import TextSmall from '../TextSmall'
import Navigation from '../layout/Navigation'
import QuestionExtraComment from '../QuestionExtraComment'
import QuestionExtraInput from '../QuestionExtraInput'
import MainPhoto from '../MainPhoto'
import { IOnChangeProps } from '../../pages/ResponderView'

import Style from '../../styles/Questions.module.sass'

interface IQuestionSingleResponseImageProps {
  slideBg: React.CSSProperties
  question: ISurveyQuestion
  questionResponses?: IQuestionResponse
  onChange: (props: IOnChangeProps) => void
}

const QuestionSingleMultiResponseImage: FC<IQuestionSingleResponseImageProps> = (props) => {
  const { questionResponses, question, slideBg, onChange } = props
  const { id, text_big, text_small, photo_main, background, answers, type, confirm_visible } = question

  const isBright = background === 'white'
  const pageStyle = `${Style.pageContent} ${isBright ? Style.isBright : Style.isDark}`

  const inputAnswer = answers.find((a) => a.type === AnswerTypeEnum.TEXT_INPUT)
  const extraInput = questionResponses?.responses.find((r) => r.answer_id === inputAnswer?.id)

  const commentAnswer = answers.find((a) => a.type === AnswerTypeEnum.TEXT_COMMENT)
  const extraComment = questionResponses?.responses.find((r) => r.answer_id === commentAnswer?.id)

  return (
    <div className={Style.pageWrapper}>
      <div className={pageStyle} style={slideBg}>
        <Header isBright={isBright} showProgress={true} />

        <div className={Style.questionText}>
          <h3>{text_big}</h3>
        </div>
        <TextSmall text={text_small} />

        <MainPhoto image={photo_main} alt={text_big} />

        <div className={Style.answerImage}>
          {answers.map((answer) => {
            if (answer.type !== AnswerTypeEnum.DEFAULT) return null

            const selected = questionResponses?.responses.some((r) => r.answer_id === answer.id) || false

            const showConfirmText = confirm_visible ? selected && answer.description : answer.description

            return (
              <div key={answer.id} className={Style.imageItemWrapper}>
                <div className={Style.imageItem}>
                  {!answer.photo && <div className={Style.noImage} />}
                  {answer.photo && (
                    <div className={Style.image}>
                      <img src={`/v1/files/image/${answer.photo}`} alt={answer.text} />
                    </div>
                  )}
                  <div className={Style.checkbox}>
                    <Checkbox
                      cssClass='checkbox-fix'
                      label={answer.text}
                      checked={selected}
                      onChange={() => onChange({ id, answer, type, input: null, comment: null })}
                    />
                  </div>
                  {showConfirmText && <p className={Style.description}>{answer.description}</p>}
                </div>
              </div>
            )
          })}
        </div>

        <div className={Style.answers}>
          <QuestionExtraInput
            id={id}
            answers={answers}
            value={extraInput?.input}
            type={type}
            selected={!!extraInput?.input}
            isBright={isBright}
            onChange={onChange}
          />

          <QuestionExtraComment
            id={id}
            type={type}
            answers={answers}
            value={extraComment?.comment}
            onChange={onChange}
          />

          <Navigation isBright={isBright} />
        </div>

        <Footer isBright={isBright} />
      </div>
    </div>
  )
}

export default QuestionSingleMultiResponseImage
