import { FC, Fragment, useMemo } from 'react'
import classNames from 'classnames'
import { Button } from '@aurecon-creative-technologies/styleguide'

import { IQuestionResponse, ISurveyQuestion } from '../../api/model'
import { PageEnum } from '../../enums/PageTypesEnum'
import AnswerTypeEnum from '../../enums/AnswerTypeEnum'

import Header from '../layout/Header'
import Footer from '../layout/Footer'
import TextSmall from '../TextSmall'
import Navigation from '../layout/Navigation'
import QuestionExtraComment from '../QuestionExtraComment'
import MainPhoto from '../MainPhoto'
import { IOnChangeProps } from '../../pages/ResponderView'

import Style from '../../styles/Questions.module.sass'

interface IQuestionRankingProps {
  slideBg: React.CSSProperties
  question: ISurveyQuestion
  questionResponses?: IQuestionResponse
  onChange: (props: IOnChangeProps) => void
  onReset: (id: string) => void
}

const QuestionRanking: FC<IQuestionRankingProps> = (props) => {
  const { questionResponses, question, slideBg, onChange, onReset } = props
  const { id, text_big, text_small, photo_main, background, answers, type, confirm_visible } = question

  const isBright = background === 'white'

  const pageStyleClasses = classNames({
    [Style.pageContent]: true,
    [Style.isBright]: isBright,
    [Style.isDark]: !isBright,
  })

  const commentAnswer = useMemo(() => {
    const answer = answers.find((a) => a.type === AnswerTypeEnum.TEXT_COMMENT)
    return questionResponses?.responses.find((r) => r.answer_id === answer?.id)
  }, [answers, questionResponses?.responses])

  return (
    <div className={Style.pageWrapper}>
      <div className={pageStyleClasses} style={slideBg}>
        <Header isBright={isBright} showProgress={true} />
        <div className={Style.questionText}>
          <h3>{text_big}</h3>
        </div>
        <TextSmall text={text_small} />

        <MainPhoto image={photo_main} alt={text_big} />

        <div className={Style.answers}>
          {answers.map((answer) => {
            if (answer.type !== AnswerTypeEnum.DEFAULT) return null

            const answerIndex = questionResponses
              ? questionResponses.responses
                  .filter((r) => r.type === AnswerTypeEnum.DEFAULT)
                  .findIndex((r) => r.answer_id === answer.id)
              : -1
            const ranking = answerIndex !== -1 ? answerIndex + 1 : '-'

            const selected = questionResponses?.responses.some((r) => r.answer_id === answer.id) || false
            const optionClasses = classNames({
              [Style.option]: true,
              [Style.active]: selected,
            })
            const showConfirmText = confirm_visible ? selected && answer.description : answer.description

            return (
              <Fragment key={answer.id}>
                <div
                  className={Style.ranking}
                  onClick={() => onChange({ id, answer, type, input: null, comment: null })}
                  role='none'
                >
                  <div className={Style.rankingBox}>
                    <p>{ranking}</p>
                  </div>
                  <p>{answer.text}</p>
                </div>
                <div key={`${answer.id}_desc`} className={optionClasses}>
                  {showConfirmText && <p className={Style.description}>{answer.description}</p>}
                </div>
              </Fragment>
            )
          })}

          <Button
            label='Reset Answer'
            type='secondary'
            size='extra small'
            cssClass={Style.reset}
            onClick={() => onReset(id)}
          />

          <QuestionExtraComment
            id={id}
            type={PageEnum.RANKING}
            answers={answers}
            value={commentAnswer?.comment}
            onChange={onChange}
          />

          <Navigation isBright={isBright} />
        </div>

        <Footer isBright={isBright} />
      </div>
    </div>
  )
}

export default QuestionRanking
