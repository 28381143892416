import { FC } from 'react'
import { Checkbox, FormInput } from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'

import AnswerTypeEnum from '../enums/AnswerTypeEnum'
import { IAnswer } from '../api/model'

import Style from '../styles/QuestionExtraText.module.sass'

interface IQuestionExtraInputProps {
  id: string
  answers: IAnswer[]
  value: string | undefined
  type: number
  selected: boolean
  isBright: boolean
  onChange: (props: { id: string; answer: IAnswer; type: number; input: string | null; comment: string | null }) => void
}

const QuestionExtraInput: FC<IQuestionExtraInputProps> = (props) => {
  const { id, answers, value, type, selected, isBright, onChange } = props

  const answer = answers.find((a) => a.type === AnswerTypeEnum.TEXT_INPUT)
  if (!answer) return null

  const handleCheckbox = () => {
    onChange({ id, answer, type, input: null, comment: null })
  }

  const handleTextChange = (text: string) => {
    onChange({ id, answer, type, input: text, comment: null })
  }

  const extraInputClasses = classNames(Style.extraInputWrapper, { [Style.isDark]: !isBright })

  return (
    <div className={extraInputClasses}>
      <Checkbox cssClass='checkbox-fix' label={answer.text} checked={selected} onChange={handleCheckbox} />
      <FormInput placeholder='Please type here...' value={value ?? ''} onChange={handleTextChange} />
    </div>
  )
}

export default QuestionExtraInput
