import { FC, useMemo } from 'react'
import { Grid, Button, Loader, Tooltip, Icon } from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'
import { format } from 'date-fns'

import { IApiKeyDetails } from '../../api/admin/survey'

import Style from '../../styles/AddEditSurvey.module.sass'

interface IAddEditSurveyInfoApiKey {
  no_preview: boolean
  apiKey?: IApiKeyDetails | null
  apiKeyLoading: boolean
  editMode: boolean
  handleGenerateApiKey: () => void
}

export const AddEditSurveyInfoApiKey: FC<IAddEditSurveyInfoApiKey> = (props) => {
  const { no_preview, apiKey, apiKeyLoading, editMode, handleGenerateApiKey } = props

  const expiryDate = useMemo(() => {
    if (apiKeyLoading || !apiKey) return ''

    const expDate = new Date(apiKey.expired_date)

    return format(expDate, 'dd MMMM yyyy')
  }, [apiKey, apiKeyLoading])

  const isKeyExpired = useMemo(() => {
    if (apiKeyLoading || !apiKey) return ''

    const expiredDate = new Date(apiKey.expired_date)
    const currentDate = new Date()
    return expiredDate < currentDate
  }, [apiKey, apiKeyLoading])

  const apiKeyIconClasses = classNames({
    [Style.apiKeyStatusIcon]: true,
    [Style.keyValid]: !isKeyExpired,
    [Style.keyInvalid]: isKeyExpired,
  })

  if (!no_preview || !editMode) return null

  return (
    <Grid item xs={12} md={12} cssClass={Style.surveyIdContainer}>
      <span className={Style.surveyIdLabel}>
        API Key
        {!apiKeyLoading && (
          <Tooltip show={<span>{`Valid until: ${expiryDate}`}</span>}>
            <Icon cssClass={apiKeyIconClasses} size='20px' type={isKeyExpired ? 'cancel' : 'check_circle'} />
          </Tooltip>
        )}
      </span>
      <span className={Style.surveyId}>
        {apiKeyLoading ? (
          <>
            generating API Key...
            <Loader size='extra small' cssClass={Style.loaderIcon} />
          </>
        ) : (
          <>
            {apiKey ? (
              <>
                {apiKey?.id}
                <Button
                  type='icon-secondary'
                  icon='content_paste'
                  title='Copy API Key'
                  cssClass={Style.copyIcon}
                  onClick={() => navigator.clipboard.writeText(apiKey?.id || '')}
                />
              </>
            ) : (
              <>{'No API Key generated'}</>
            )}
            <Button
              type='icon-secondary'
              icon='sync'
              title='Generate new API key'
              cssClass={Style.generateIcon}
              onClick={handleGenerateApiKey}
            />
          </>
        )}
      </span>
    </Grid>
  )
}
