import { FC } from 'react'
import { Icon } from '@aurecon-creative-technologies/styleguide'

import { IQuestionResponse, ISurveyQuestion } from '../../api/model'

import Header from '../layout/Header'
import Footer from '../layout/Footer'
import TextSmall from '../TextSmall'
import Navigation from '../layout/Navigation'
import QuestionExtraComment from '../QuestionExtraComment'
import AnswerTypeEnum from '../../enums/AnswerTypeEnum'
import MainPhoto from '../MainPhoto'
import { IOnChangeProps } from '../../pages/ResponderView'

import Style from '../../styles/Questions.module.sass'

interface IQuestionSingleResponseButtonProps {
  slideBg: React.CSSProperties
  question: ISurveyQuestion
  questionResponses?: IQuestionResponse
  onChange: (props: IOnChangeProps) => void
}

const QuestionSingleResponseButton: FC<IQuestionSingleResponseButtonProps> = (props) => {
  const { questionResponses, question, slideBg, onChange } = props
  const { id, text_big, text_small, photo_main, background, answers, type, confirm_visible } = question

  const isBright = background === 'white'
  const pageStyle = `${Style.pageContent} ${isBright ? Style.isBright : Style.isDark}`

  const commentAnswer = answers.find((a) => a.type === AnswerTypeEnum.TEXT_COMMENT)
  const extraComment = questionResponses?.responses.find((r) => r.answer_id === commentAnswer?.id)

  return (
    <div className={Style.pageWrapper}>
      <div className={pageStyle} style={slideBg}>
        <Header isBright={isBright} showProgress={true} />
        <div className={Style.questionText}>
          <h3>{text_big}</h3>
        </div>
        <TextSmall text={text_small} />

        <MainPhoto image={photo_main} alt={text_big} />

        <div className={Style.answerWrapper}>
          {answers.map((answer) => {
            if (answer.type !== AnswerTypeEnum.DEFAULT) return null

            const selected = questionResponses?.responses.some((r) => r.answer_id === answer.id) || false
            const optionClasses = selected ? `${Style.option} ${Style.active}` : Style.option
            const showConfirmText = confirm_visible ? selected && answer.description : answer.description

            return (
              <div key={answer.id} className={optionClasses}>
                <button
                  className={Style.optionButton}
                  onClick={() => onChange({ id, answer, type, input: null, comment: null })}
                >
                  {selected && <Icon type='check' />}
                </button>
                <p>{answer.text}</p>
                {showConfirmText && <p className={Style.description}>{answer.description}</p>}
              </div>
            )
          })}
        </div>

        <div className={Style.answers}>
          <QuestionExtraComment
            id={id}
            type={type}
            answers={answers}
            value={extraComment?.comment}
            onChange={onChange}
          />
          <Navigation isBright={isBright} />
        </div>

        <Footer isBright={isBright} />
      </div>
    </div>
  )
}

export default QuestionSingleResponseButton
