import { FC, useState } from 'react'
import { RecoilRoot } from 'recoil'
import { Toast, ToastProvider } from '@aurecon-creative-technologies/styleguide'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import Login from './pages/Login'
import { NotFound } from './pages/NotFound'
import ResponderView from './pages/ResponderView'
import SurveyList from './pages/SurveyList'
import QuestionList from './pages/QuestionList'
import Profile from './pages/Profile'
import AddEditSurvey from './pages/AddEditSurvey'
import AddEditQuestion from './pages/AddEditQuestion'
import SurveyReport from './pages/SurveyReport'
import AddEditScorecard from './pages/AddEditScorecard'
import TermsAndConditions from './pages/TermsAndConditions'
import PrivacyPolicy from './pages/PrivacyPolicy'
import CookiesPolicy from './pages/CookiesPolicy'
import LoadingScreen from './components/LoadingScreen'
import Consent from './components/Consent'
import { createAppInsightContext } from './api/AppInsights'

import Style from './styles/App.module.sass'

const publicRoutes = [
  <Route key='login' path='/login' element={<Login />} />,
  <Route key='termsandconditions' path='/termsandconditions' element={<TermsAndConditions />} />,
  <Route key='cookies' path='/cookies' element={<CookiesPolicy />} />,
  <Route key='privacy' path='/privacy' element={<PrivacyPolicy />} />,
  <Route key='responder' path='/' element={<ResponderView />} />,
  <Route key='responderLink' path='/:link' element={<ResponderView />} />,
  <Route key='notfound' path='*' element={<NotFound />} />,
]

const privateRoutes = [
  <Route key='surveys' path='/surveys' element={<SurveyList />} />,
  <Route key='profile' path='/profile' element={<Profile />} />,
  <Route key='editSurvey' path='/editSurvey/:survey_id' element={<AddEditSurvey />} />,
  <Route key='addSurvey' path='/addSurvey' element={<AddEditSurvey />} />,
  <Route key='survey' path='/survey/:survey_id' element={<QuestionList />} />,
  <Route key='surveyReport' path='/surveyReport/:survey_id' element={<SurveyReport />} />,
  <Route key='editQuestion' path='/surveys/:survey_id/:question_id/:page_type?' element={<AddEditQuestion />} />,
  <Route key='addQuestion' path='/addQuestion/:survey_id/:page_type?' element={<AddEditQuestion />} />,
  <Route key='addScorecard' path='/addScorecard/:survey_id' element={<AddEditScorecard />} />,
]

const App: FC = () => {
  const { isLoading, error, isAuthenticated } = useAuth0()
  const [isConsented, setIsConsented] = useState(false)
  const [authContext, setAuthContext] = useState(false)

  if (error) {
    return (
      <div className={Style.errorWrapper}>
        <div className={Style.innerWrapper}>
          <p>swipEngage</p>
          <p>Oops... Looks like we have an issue...</p>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return (
      <RecoilRoot>
        <LoadingScreen text='Loading application...' />
      </RecoilRoot>
    )
  }

  if (!isAuthenticated) {
    return (
      <RecoilRoot>
        <HashRouter>
          <Routes>{publicRoutes}</Routes>
        </HashRouter>
      </RecoilRoot>
    )
  }

  if (!isConsented) {
    return (
      <RecoilRoot>
        <Consent
          setConsented={() => {
            setIsConsented(true)
          }}
        />
      </RecoilRoot>
    )
  }

  if (!authContext) {
    createAppInsightContext()
    setAuthContext(true)
  }

  return (
    <RecoilRoot>
      <ToastProvider>
        <HashRouter>
          <Routes>
            {publicRoutes}
            {privateRoutes}
          </Routes>
        </HashRouter>
        <Toast />
      </ToastProvider>
    </RecoilRoot>
  )
}

export default App
