import { FC } from 'react'
import { FormInput } from '@aurecon-creative-technologies/styleguide'

import { IQuestionResponse, ISurveyQuestion } from '../../api/model'
import AnswerTypeEnum from '../../enums/AnswerTypeEnum'

import Header from '../layout/Header'
import Footer from '../layout/Footer'
import TextSmall from '../TextSmall'
import Navigation from '../layout/Navigation'
import MainPhoto from '../MainPhoto'
import { IOnChangeProps } from '../../pages/ResponderView'

import Style from '../../styles/Questions.module.sass'

interface IQuestionContactProps {
  slideBg: React.CSSProperties
  question: ISurveyQuestion
  questionResponses: IQuestionResponse | undefined
  onChange: (props: IOnChangeProps) => void
}

const QuestionContact: FC<IQuestionContactProps> = (props) => {
  const { slideBg, questionResponses, question, onChange } = props
  const { id, text_big, text_small, photo_main, background, answers, type } = question

  const isBright = background === 'white'
  const pageStyle = `${Style.pageContent} ${isBright ? Style.isBright : Style.isDark}`

  return (
    <div className={Style.pageWrapper}>
      <div className={pageStyle} style={slideBg}>
        <Header isBright={isBright} showProgress={true} />
        <div className={Style.questionText}>
          <h3>{text_big}</h3>
        </div>
        <TextSmall text={text_small} />

        <MainPhoto image={photo_main} alt={text_big} />

        <div className={Style.contactForm}>
          {answers.map((answer) => {
            if (answer.type !== AnswerTypeEnum.TEXT_INPUT) return null

            const response = questionResponses?.responses.find((r) => r.answer_id === answer?.id)

            return (
              <FormInput
                key={answer.id}
                label={answer.text || undefined}
                type='text'
                placeholder={answer.placeholder ?? 'Please type here'}
                value={response?.input}
                onChange={(text) => onChange({ id, answer, type, input: text, comment: null })}
                cssClass='is-contact'
                required={!!answer.validation}
              />
            )
          })}

          <Navigation isBright={isBright} />
        </div>

        <Footer isBright={isBright} />
      </div>
    </div>
  )
}

export default QuestionContact
