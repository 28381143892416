import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import classNames from 'classnames'
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import {
  Switcher,
  Container,
  Grid,
  DateInput,
  IDateInputDates,
  Dropdown,
} from '@aurecon-creative-technologies/styleguide'
import { startOfDay, endOfDay, format, addDays, addMonths } from 'date-fns'

import { getSurveyCustomOverview, getSurveyDayOverview, IChartData, overviewDateRange } from '../api/admin/dashboard'
import NavbarSubTabEnum from '../enums/NavbarSubTabEnum'
import { CurrentSurveyId, Surveys, NavbarSubTab, SurveyOverviewRange } from '../store/AdminStore'

import AdminLayout from '../components/layout/AdminLayout'
import { DashboardOverview } from '../components/dashboard/DashboardOverview'
import { DashboardQuestions } from '../components/dashboard/DashboardQuestions'
import { DashboardCSVDownload } from '../components/dashboard/DashboardCSVDownload'
import { DashboardFileUpload } from '../components/dashboard/DashboardFileUpload'
import { populateDaysBetweenDates } from '../helpers/populateDaysBetweenDates'
import { DashboardRangesEnum } from '../enums/DashboardRangesEnum'
import { mergeDates } from '../helpers/meregeDates'
import { mergeHours } from '../helpers/mergeHours'

import Style from '../styles/SurveyReport.module.sass'

const SurveyReport: FC = () => {
  const surveyId = useParams().survey_id ?? ''
  const setCurrentSurveyId = useSetRecoilState(CurrentSurveyId)
  const [chartView, setChartView] = useState('Overview')
  const surveys = useRecoilValueLoadable(Surveys)
  const setNavbarSubTab = useSetRecoilState(NavbarSubTab)
  const [surveyOverviewRange, setSurveyOverviewRange] = useRecoilState(SurveyOverviewRange)
  const [dateRange, setDateRange] = useState<IDateInputDates>({ startDate: null, endDate: null })
  const [dataLoading, setDataLoading] = useState<boolean>(false)

  const [chartData, setChartData] = useState<IChartData[]>([])

  useEffect(() => {
    handleDateRangeChange(surveyOverviewRange)
  }, []) // eslint-disable-line

  useEffect(() => {
    setNavbarSubTab(NavbarSubTabEnum.RESULTS)
  }, [setNavbarSubTab])

  useEffect(() => {
    if (surveys.state === 'hasValue') {
      if (surveys.contents.find((s) => s.id === surveyId)) setCurrentSurveyId(surveyId)
      else window.location.hash = '/surveys'
    }
  }, [setCurrentSurveyId, surveys, surveyId])

  const surveyData = surveys.valueMaybe()?.find((s) => s.id === surveyId)

  const surveyName = surveyData ? surveyData.name : 'Loading data...'

  const handleDateRangeChange = async (overviewRange: overviewDateRange, overviewDates?: IDateInputDates) => {
    setDataLoading(true)
    setSurveyOverviewRange(overviewRange)

    if (overviewRange === DashboardRangesEnum.DAY) {
      const data = await getSurveyDayOverview({ surveyId })
      const mergedHours = mergeHours(data)

      setChartData(mergedHours)
      setDataLoading(false)
      return
    }

    const today = new Date()
    let startDate: Date | null = null
    let endDate: Date | null = endOfDay(today)

    if (overviewRange === DashboardRangesEnum.WEEK) {
      startDate = startOfDay(addDays(today, -7))
    } else if (overviewRange === DashboardRangesEnum.MONTH) {
      startDate = startOfDay(addMonths(today, -1))
    } else if (overviewRange === DashboardRangesEnum.CUSTOM) {
      startDate = overviewDates?.startDate || dateRange.startDate
      endDate = overviewDates?.endDate || dateRange.endDate
    } else startDate = startOfDay(surveyData?.mod_date || today)

    if (!startDate || !endDate) {
      setChartData([])
      return
    }
    const dates = populateDaysBetweenDates({ startDate, endDate, formatDate: 'dd/MM/yyyy' })
    const response = await getSurveyCustomOverview({ surveyId, startDate, endDate })

    const mergedDates = mergeDates(dates, response)

    setDateRange({ startDate, endDate })
    setChartData(mergedDates)
    setDataLoading(false)

    return
  }

  const handleDateChange = async (value: IDateInputDates) => {
    const startDate = value.startDate ? startOfDay(value.startDate) : null
    const endDate = value.endDate ? endOfDay(value.endDate) : null

    if (!startDate || !endDate) return
    handleDateRangeChange(DashboardRangesEnum.CUSTOM as overviewDateRange, { startDate, endDate })
  }

  const csvContainerClasses = classNames({
    [Style.surveyOptionsItem]: true,
    [Style.downloadCsvContainer]: true,
  })

  return (
    <AdminLayout>
      <div>
        <Container fluid cssClass={Style.mainContainer}>
          <Grid row gap={12}>
            <Grid item xs={12}>
              <h1>Dashboard</h1>
            </Grid>

            <Grid item xs={12} md={6}>
              <h2>{surveyName}</h2>
            </Grid>
            <Grid item xs={12} md={6} cssClass={Style.dateText}>
              <p>Today's date</p>
              <h2>{format(new Date(), 'd MMMM yyyy')}</h2>
            </Grid>

            <Grid item xs={12} cssClass={Style.surveyOptionsContainer}>
              <div className={Style.surveyOptionsItem}>
                <Switcher
                  items={[
                    { id: 'Overview', label: 'Overview' },
                    { id: 'Questions', label: 'Questions' },
                    { id: 'File Upload', label: 'File Upload' },
                  ]}
                  selectedItem={chartView}
                  onSelectItem={(item) => setChartView(item as string)}
                />
              </div>
              <div className={Style.surveyOptionsItem}>
                <div className={Style.dateContainer}>
                  <Dropdown
                    items={[
                      { id: DashboardRangesEnum.DAY, label: 'Today' },
                      { id: DashboardRangesEnum.WEEK, label: 'Last Week' },
                      { id: DashboardRangesEnum.MONTH, label: 'Last Month' },
                      { id: DashboardRangesEnum.CUSTOM, label: 'Custom' },
                    ]}
                    onSelectItem={(item) => handleDateRangeChange(item as overviewDateRange)}
                    selectedItem={surveyOverviewRange}
                    default
                  />
                  {surveyOverviewRange === DashboardRangesEnum.CUSTOM && (
                    <DateInput dates={dateRange} rangeType onDateChange={handleDateChange} />
                  )}
                </div>
              </div>
              <div className={csvContainerClasses}>
                <DashboardCSVDownload name={surveyName} />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Grid row gap={12}>
                {chartView == 'Overview' && <DashboardOverview chartData={chartData} dataLoading={dataLoading} />}
                {chartView == 'Questions' && <DashboardQuestions surveyId={surveyId} customDateRange={dateRange} />}
                {chartView == 'File Upload' && <DashboardFileUpload customDateRange={dateRange} />}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </AdminLayout>
  )
}

export default SurveyReport
