import { IChartData } from '../api/admin/dashboard'

export const mergeDates = (dates: string[], data: IChartData[] | null) => {
  return dates.map((d) => {
    let total = 0
    data?.forEach((r) => {
      if (r.date === d) total = r.total
    })

    return { date: d, total }
  })
}
