import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  useRecoilState,
  useRecoilStateLoadable,
  useRecoilValueLoadable,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil'
import { SwipeViewsVirtual } from '@qbakozak/react-swipe-views'

import SlideTitle from '../components/surveyPages/SlideTitle'
import SlideSubmit from '../components/surveyPages/SlideSubmit'
import ValidationModal from '../components/ValidationModal'
import SubmitErrorModal from '../components/SubmitErrorModal'
import RestoreResponsesModal from '../components/RestoreResponsesModal'

import QuestionRanking from '../components/surveyPages/QuestionRanking'
import QuestionSingleResponseButton from '../components/surveyPages/QuestionSingleResponseButton'
import QuestionSingleMultiResponse from '../components/surveyPages/QuestionSingleMultiResponse'
import QuestionSingleMultiResponseImage from '../components/surveyPages/QuestionSingleMultiResponseImage'
import QuestionContact from '../components/surveyPages/QuestionContact'
import QuestionComment from '../components/surveyPages/QuestionComment'
import SlideLoading from '../components/surveyPages/SlideLoading'
import SlideNotAvailable from '../components/surveyPages/SlideNotAvailable'
import ScoreCard from '../components/surveyPages/ScoreCard'
import Divider from '../components/surveyPages/Divider'

import { PageEnum } from '../enums/PageTypesEnum'
import AnswerTypeEnum from '../enums/AnswerTypeEnum'
import { IAnswer, IAnswerResponse, IQuestionResponse, ISurveyQuestion } from '../api/model'
import { validateSurvey } from '../helpers/surveyValidation'
import { submitSurvey } from '../api/survey'
import { isSingleResponseQuestion } from '../helpers/maxScoreComputation'
import QuestionFileUpload from '../components/surveyPages/QuestionFileUpload'

import {
  Survey,
  SurveyLink,
  ResponseData,
  SlideIndex,
  ShowErrorModal,
  ShowRestoreModal,
  AvailableQuestions,
} from '../store/AppStore'

import Style from '../styles/Survey.module.sass'

export interface IOnChangeProps {
  id: string
  answer: IAnswer | undefined
  type: number
  input: string | null
  comment: string | null
  attachment?: File[]
}

interface SyncResponsesProps {
  itemExists: boolean
  responsesNew: IAnswerResponse[]
  responseItem: IAnswerResponse
  type: number
  answerIndex: number
}

export interface IQuestionAttachments {
  question_id: string
  files: File[]
}

const ResponderView: FC = () => {
  const link = useParams().link
  const setSurveyLink = useSetRecoilState(SurveyLink)
  const survey = useRecoilValueLoadable(Survey)
  const availableQuestions = useRecoilValueLoadable(AvailableQuestions)
  const [responseData, setResponseData] = useRecoilStateLoadable(ResponseData)
  const resetResponses = useResetRecoilState(ResponseData)
  const [slideIndex, setSlideIndex] = useRecoilState(SlideIndex)
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const setShowErrorModal = useSetRecoilState(ShowErrorModal)
  const setShowRestoreModal = useSetRecoilState(ShowRestoreModal)
  const [validationErrors, setValidationErrors] = useState<string[]>([])
  const [checkedStorage, setCheckedStorage] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)
  const [questionAttachments, setQuestionAttachments] = useState<IQuestionAttachments[]>([])
  const [errorModalMessage, setErrorModalMessage] = useState<string>()
  const [files, setFiles] = useState<File[]>([])

  useEffect(() => {
    setSlideIndex(0)
    setSurveyLink(link)
    setShowErrorModal(false)
    setValidationErrors([])
    resetResponses()
  }, [link, setSurveyLink, resetResponses, setSlideIndex, setShowErrorModal])

  useEffect(() => {
    if (survey.state === 'hasError' && !link) window.location.hash = `/login`
    if (survey.state !== 'hasValue' || !survey.contents || checkedStorage) return

    const responseString = localStorage.getItem(`swe-${survey.contents.id}`)
    if (responseString) setShowRestoreModal(true)

    setCheckedStorage(true)
  }, [checkedStorage, setShowRestoreModal, survey, link])

  useEffect(() => {
    if (
      responseData.state !== 'hasValue' ||
      !survey.contents ||
      survey.state !== 'hasValue' ||
      !checkedStorage ||
      !dirty
    )
      return

    const responseString = JSON.stringify(responseData.contents)
    localStorage.setItem(`swe-${survey.contents.id}`, responseString)
  }, [responseData, survey, checkedStorage, dirty])

  const updateResponse = (id: string, responsesNew: IAnswerResponse[]) => {
    setResponseData((r) => ({
      ...r,
      [id]: {
        ...r[id],
        responses: responsesNew,
      },
    }))

    setDirty(true)
  }

  const syncResponses = (props: SyncResponsesProps) => {
    const { itemExists, type, answerIndex, responseItem } = props
    let { responsesNew } = props

    switch (type) {
      case PageEnum.SINGLE_RESPONSE:
      case PageEnum.SINGLE_RESPONSE_BUTTON:
      case PageEnum.SINGLE_RESPONSE_IMAGE:
        if (itemExists) responsesNew.splice(answerIndex, 1)
        else {
          responsesNew.shift()
          responsesNew = responsesNew.filter((r) => r.type !== AnswerTypeEnum.DEFAULT)
          responsesNew.push(responseItem)
        }
        break

      case PageEnum.MULTI_RESPONSES:
      case PageEnum.MULTI_RESPONSES_IMAGE:
      case PageEnum.RANKING:
        if (itemExists) responsesNew.splice(answerIndex, 1)
        else responsesNew.push(responseItem)
        break
    }

    return responsesNew
  }

  const handleUpdate = (props: IOnChangeProps) => {
    const { id, answer, type, input, comment, attachment } = props

    const questionResponses = responseData.contents[id] as IQuestionResponse
    if (!questionResponses || !answer) return

    const answerIndex = questionResponses.responses.findIndex((r) => r.answer_id === answer.id)
    const itemExists = answerIndex !== -1
    const responseItem = {
      answer_id: answer.id,
      text: answer.text,
      type: answer.type,
      weight: answer.weight,
      photo: answer.photo,
    } as IAnswerResponse
    let responsesNew = [...questionResponses.responses]

    if (answer.type === AnswerTypeEnum.TEXT_INPUT) {
      if (itemExists) responsesNew.splice(answerIndex, 1)
      if (isSingleResponseQuestion(type)) responsesNew.shift()
      responseItem.input = input || ''
      responsesNew.push(responseItem)
      updateResponse(id, responsesNew)
      return
    }

    if (answer.type === AnswerTypeEnum.TEXT_COMMENT) {
      if (itemExists) responsesNew.splice(answerIndex, 1)
      if (isSingleResponseQuestion(type)) responsesNew.shift()
      responseItem.comment = comment || ''
      responsesNew.push(responseItem)
      updateResponse(id, responsesNew)
      return
    }

    if (answer.type === AnswerTypeEnum.FILE_UPLOAD && attachment) {
      const updatedAttachments = [...questionAttachments]
      let attachmentFound = false
      updatedAttachments.forEach((q) => {
        if (q.question_id === id) {
          q.files = attachment
          attachmentFound = true
        }
      })
      if (!attachmentFound) {
        updatedAttachments.push({
          question_id: id,
          files: attachment,
        })
      }

      const item = { ...responseItem, attachment }
      if (itemExists) responsesNew.splice(answerIndex, 1)
      if (isSingleResponseQuestion(type)) responsesNew.shift()
      responsesNew.push(item)
      updateResponse(id, responsesNew)

      setQuestionAttachments(updatedAttachments)

      return
    }

    responsesNew = syncResponses({
      itemExists,
      responsesNew,
      responseItem,
      type,
      answerIndex,
    })

    updateResponse(id, responsesNew)
  }

  const handleResetRanking = (id: string) => {
    updateResponse(id, [])
  }

  const submitForm = async () => {
    const validation = validateSurvey(availableQuestions.contents, responseData.contents)
    if (validation.errors.length) {
      setValidationErrors(validation.errors)
      setSlideIndex(validation.slideIndex)
      return
    }

    setSubmitting(true)
    setErrorModalMessage('')
    const result = await submitSurvey(responseData.contents, survey.contents.id, questionAttachments)

    if (result.status === 400) {
      setErrorModalMessage(JSON.parse(await result.text()))
      setShowErrorModal(true)
    } else if (result.ok) {
      setSubmitted(true)
      localStorage.removeItem(`swe-${survey.contents.id}`)
    } else {
      console.error(await result.text())
      setShowErrorModal(true)
    }

    setSubmitting(false)
  }

  const slideRenderer = (params: { index: number; key: number }) => {
    const index = Number(params.index)

    if (index >= availableQuestions.contents.length)
      return (
        <SlideSubmit
          key='submit'
          saving={submitting}
          submitDone={submitted}
          onSubmit={submitForm}
          renderPDF={slideIndex === availableQuestions.contents.length}
        />
      )

    const question: ISurveyQuestion = availableQuestions.contents[index]

    const slideBg = question.photo_bg
      ? {
          backgroundImage: `url('/v1/files/image/${question.photo_bg}')`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }
      : {}

    const questionResponses = responseData.contents[question.id] as IQuestionResponse

    switch (question.type) {
      case PageEnum.TITLE:
        return <SlideTitle key={index} slideBg={slideBg} survey={survey.contents} />
      case PageEnum.MULTI_RESPONSES:
      case PageEnum.SINGLE_RESPONSE:
        return (
          <QuestionSingleMultiResponse
            key={index}
            slideBg={slideBg}
            question={question}
            questionResponses={questionResponses}
            onChange={handleUpdate}
          />
        )
      case PageEnum.SINGLE_RESPONSE_IMAGE:
      case PageEnum.MULTI_RESPONSES_IMAGE:
        return (
          <QuestionSingleMultiResponseImage
            key={index}
            slideBg={slideBg}
            question={question}
            questionResponses={questionResponses}
            onChange={handleUpdate}
          />
        )
      case PageEnum.SINGLE_RESPONSE_BUTTON:
        return (
          <QuestionSingleResponseButton
            key={index}
            slideBg={slideBg}
            question={question}
            questionResponses={questionResponses}
            onChange={handleUpdate}
          />
        )
      case PageEnum.RANKING:
        return (
          <QuestionRanking
            key={index}
            slideBg={slideBg}
            question={question}
            questionResponses={questionResponses}
            onChange={handleUpdate}
            onReset={handleResetRanking}
          />
        )
      case PageEnum.COMMENT:
        return (
          <QuestionComment
            key={index}
            slideBg={slideBg}
            questionResponses={questionResponses}
            question={question}
            onChange={handleUpdate}
          />
        )
      case PageEnum.CONTACT:
        return (
          <QuestionContact
            key={index}
            slideBg={slideBg}
            question={question}
            questionResponses={questionResponses}
            onChange={handleUpdate}
          />
        )
      case PageEnum.FILE_UPLOAD:
        return (
          <QuestionFileUpload
            key={index}
            slideBg={slideBg}
            question={question}
            onChange={handleUpdate}
            setFiles={setFiles}
            files={files}
          />
        )
      case PageEnum.DIVIDER:
        return <Divider key={index} slideBg={slideBg} question={question} />
      case PageEnum.SCORECARD:
        return <ScoreCard key='score-card' />
      default:
        return <div key={index} />
    }
  }

  if (survey.state === 'hasError' || availableQuestions.state === 'hasError' || responseData.state === 'hasError')
    return <SlideNotAvailable />

  if (survey.state === 'hasValue' && availableQuestions.state === 'hasValue' && responseData.state === 'hasValue') {
    return (
      <div className={Style.surveyPages}>
        <ValidationModal
          show={validationErrors.length > 0}
          errors={validationErrors}
          onClose={() => setValidationErrors([])}
        />
        <SubmitErrorModal errorMessage={errorModalMessage} />
        <RestoreResponsesModal />
        <SwipeViewsVirtual
          enableMouseEvents
          resistance
          index={slideIndex}
          onChangeIndex={setSlideIndex}
          slideRenderer={slideRenderer}
          slideCount={availableQuestions.contents.length + 1}
        />
      </div>
    )
  }

  return <SlideLoading />
}

export default ResponderView
