import { FC } from 'react'
import { Grid, Button } from '@aurecon-creative-technologies/styleguide'

import Style from '../../styles/AddEditSurvey.module.sass'

interface IAddEditSurveyInfoApiKey {
  surveyId: string | null
  editMode: boolean
}

export const AddEditSurveyInfoId: FC<IAddEditSurveyInfoApiKey> = (props) => {
  const { surveyId, editMode } = props

  if (!editMode) return null

  return (
    <Grid item xs={12} md={12} cssClass={Style.surveyIdContainer}>
      <span className={Style.surveyIdLabel}>Survey ID</span>
      <span className={Style.surveyId}>
        {surveyId}
        <Button
          type='icon-secondary'
          icon='content_paste'
          title='Copy survey ID'
          cssClass={Style.copyIcon}
          onClick={() => navigator.clipboard.writeText(surveyId || '')}
        />
      </span>
    </Grid>
  )
}
