import { useCallback } from 'react'
import { atom, selector, useSetRecoilState } from 'recoil'
import { getAppVersion } from '../api/admin'
import { getApiKey, getSurveys } from '../api/admin/survey'
import { getSurveyQuestions } from '../api/admin/question'
import { getQuestionAnswers } from '../api/admin/answer'
import NavbarTabEnum from '../enums/NavbarTabEnum'
import { getCSVResponses, getSurveyAttachments, getSurveyTotal, overviewDateRange } from '../api/admin/dashboard'
import { getScorecard } from '../api/scorecard'
import { getSurveyCategories } from '../api/admin/category'
import { DashboardRangesEnum } from '../enums/DashboardRangesEnum'

export const CurrentSurveyId = atom<string | null>({
  key: 'currentSurveyId',
  default: null,
})

export const CurrentQuestionId = atom<string | null>({
  key: 'currentQuestionId',
  default: null,
})

export const CurrentSurvey = selector({
  key: 'currentSurvey',
  get: ({ get }) => {
    const surveys = get(Surveys)
    const id = get(CurrentSurveyId)
    return surveys.find((s) => s.id === id) || null
  },
})

export const SurveyListSortIndex = atom({
  key: 'surveyListSortIndex',
  default: 0,
})

export const SurveyListPageIndex = atom({
  key: 'surveyListPageIndex',
  default: 1,
})

export const ShowDeleteModal = atom({
  key: 'showDeleteModal',
  default: false,
})

export const AppVersion = selector({
  key: 'appVersion',
  get: () => getAppVersion(),
})

export const RefreshSurveys = atom({
  key: 'refreshSurveys',
  default: 0,
})

export const useRefreshSurveys = () => {
  const setRefreshSurveys = useSetRecoilState(RefreshSurveys)
  return useCallback(() => setRefreshSurveys((v) => v + 1), [setRefreshSurveys])
}

export const Surveys = selector({
  key: 'surveys',
  get: ({ get }) => {
    get(RefreshSurveys)
    return getSurveys({})
  },
})

export const RefreshSurveyQuestions = atom({
  key: 'refreshSurveyQuestions',
  default: 0,
})

export const RefreshQuestionAnswers = atom({
  key: 'refreshQuestionAnswers',
  default: 0,
})

export const useRefreshSurveyQuestions = () => {
  const setRefreshSurveyQuestions = useSetRecoilState(RefreshSurveyQuestions)
  return useCallback(() => setRefreshSurveyQuestions((v) => v + 1), [setRefreshSurveyQuestions])
}

export const useRefreshSurveyAnswers = () => {
  const setRefreshSurveyAnswers = useSetRecoilState(RefreshQuestionAnswers)
  return useCallback(() => setRefreshSurveyAnswers((v) => v + 1), [setRefreshSurveyAnswers])
}

export const SurveyQuestions = selector({
  key: 'surveyQuestions',
  get: ({ get }) => {
    get(RefreshSurveyQuestions)
    const surveyId = get(CurrentSurveyId)
    return surveyId ? getSurveyQuestions({ surveyId }) : []
  },
})

export const QuestionAnswers = selector({
  key: 'questionAnswers',
  get: ({ get }) => {
    get(RefreshQuestionAnswers)
    const questionId = get(CurrentQuestionId)
    return questionId ? getQuestionAnswers({ questionId }) : []
  },
})

export const NavbarTab = atom({
  key: 'navbarTab',
  default: NavbarTabEnum.MY_SURVEYS,
})

export const NavbarSubTab = atom<number | null>({
  key: 'navbarSubTab',
  default: null,
})

interface IMainModal {
  title: string
  text: string
  loader?: boolean
}

export const MainModal = atom<IMainModal | null>({
  key: 'mainModal',
  default: null,
})

export const SurveyOverviewRange = atom<overviewDateRange>({
  key: 'surveyOverviewRange',
  default: DashboardRangesEnum.DAY as overviewDateRange,
})

export const SurveyOverviewTotal = selector({
  key: 'surveyOverviewTotal',
  get: async ({ get }) => {
    const surveyId = get(CurrentSurveyId)

    if (!surveyId) return []

    return getSurveyTotal({ surveyId })
  },
})

export const SurveyAttachments = selector({
  key: 'surveyAttachments',
  get: async ({ get }) => {
    const surveyId = get(CurrentSurveyId)

    if (!surveyId) return []

    return getSurveyAttachments({ surveyId })
  },
})

export const SurveyCSVData = selector({
  key: 'surveyCSVData',
  get: async ({ get }) => {
    const surveyId = get(CurrentSurveyId)

    if (!surveyId) return { data: [], columns: [] }

    return getCSVResponses({ surveyId })
  },
})

export const Scorecard = selector({
  key: 'scorecard',
  get: ({ get }) => {
    get(RefreshSurveyScorecard)
    const surveyId = get(CurrentSurveyId)

    if (!surveyId) return null
    return getScorecard(surveyId)
  },
})

export const RefreshSurveyScorecard = atom({
  key: 'refreshSurveyScorecard',
  default: 0,
})

export const useRefreshSurveyScorecard = () => {
  const setRefreshSurveyScorecard = useSetRecoilState(RefreshSurveyScorecard)
  return useCallback(() => setRefreshSurveyScorecard((v) => v + 1), [setRefreshSurveyScorecard])
}

export const SurveyCategories = selector({
  key: 'surveyCategories',
  get: ({ get }) => {
    get(RefreshSurveyScorecard)
    const surveyId = get(CurrentSurveyId)

    if (!surveyId) return null
    return getSurveyCategories({ surveyId })
  },
})

export const SurveyApiKey = selector({
  key: 'surveyApiKey',
  get: ({ get }) => {
    get(RefreshSurveyApiKey)
    const surveyId = get(CurrentSurveyId)
    if (!surveyId) return null
    return getApiKey({ surveyId })
  },
})

export const RefreshSurveyApiKey = atom({
  key: 'refreshSurveyApiKey',
  default: 0,
})

export const useRefreshSurveyApiKey = () => {
  const setRefreshSurveyApiKey = useSetRecoilState(RefreshSurveyApiKey)
  return useCallback(() => setRefreshSurveyApiKey((v) => v + 1), [setRefreshSurveyApiKey])
}
