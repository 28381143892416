import { FC } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@aurecon-creative-technologies/styleguide'

import Footer from '../components/layout/Footer'
import SwipEngageLogo from '../assets/swipengage-logo.svg?react'

import Style from '../styles/InfoScreen.module.sass'

export const NotFound: FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()

  const handleLogin = () => {
    loginWithRedirect({ appState: { targetUrl: window.location.hash } })
  }

  return (
    <div className={Style.pageWrapper}>
      <div className={`${Style.pageContent} ${Style.isBright}`}>
        <div className={Style.notfound}>
          <SwipEngageLogo className={Style.logo} />
          <h1>404</h1>
          <p>Page not found...</p>
          {!isAuthenticated && <Button onClick={handleLogin} label='Login' />}
        </div>

        <Footer isBright={true} />
      </div>
    </div>
  )
}
