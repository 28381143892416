import { FC, useMemo } from 'react'
import { Icon } from '@aurecon-creative-technologies/styleguide'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'
import { v1 as uuid } from 'uuid'

import { IMultiFileUpload } from '../api/model'
import { ACCEPTED_FILES, MAX_FILES, MAX_FILE_SIZE, MAX_FILE_SIZE_IN_MB } from '../config/config'

import Style from '../styles/FileUpload.module.sass'

interface IFileUploadProps {
  label?: string
  isBright: boolean
  files: IMultiFileUpload[]
  id: string
  setFiles: (file: IMultiFileUpload[]) => void
}

const FileUpload: FC<IFileUploadProps> = (props) => {
  const { label, isBright, files, id, setFiles } = props

  const activeFiles = useMemo(() => {
    return files.filter((file: IMultiFileUpload) => file.questionId === id)
  }, [files, id])

  const handleRemoveFile = (id: string) => {
    setFiles([...files].filter((file) => file.id !== id))
  }

  const onDrop = (acceptedFiles: File[]) => {
    const validFiles = acceptedFiles.filter((file) => file.size <= MAX_FILE_SIZE)
    if (validFiles.length + activeFiles.length > MAX_FILES) return

    validFiles.forEach((file: IMultiFileUpload) => {
      file.id = uuid()
      file.preview = URL.createObjectURL(file)
      file.fileType = file.type
      file.fileName = null
      file.questionId = id
    })

    setFiles([...files, ...validFiles])
  }

  const wrapperClasses = classNames({
    [Style.fileUploadWrapper]: true,
    [Style.isBright]: isBright,
    [Style.isDark]: !isBright,
  })

  const dropzoneBoxClasses = classNames({
    [Style.dropzoneBox]: true,
    [Style.dropzoneDisabled]: activeFiles.length >= MAX_FILES,
  })

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ACCEPTED_FILES,
    maxFiles: MAX_FILES - activeFiles.length,
    maxSize: MAX_FILE_SIZE,
    disabled: activeFiles.length >= MAX_FILES,
  })

  return (
    <div className={wrapperClasses}>
      {label && <p className={Style.label}>{label}</p>}
      <div className={Style.photoPreview}>
        <div className={dropzoneBoxClasses} {...getRootProps()}>
          <input {...getInputProps()} />
          <Icon cssClass={Style.addIcon} size='48px' type='picture_as_pdf' />
          <div className={Style.addPhotoText}>
            Drop files to upload or <strong> browse </strong>
            <br />
            {`(Max: ${MAX_FILES} files, Max file size: ${MAX_FILE_SIZE_IN_MB}MB)`}
            <br />
            List of supported file types:
            <br />
            {ACCEPTED_FILES.join(', ')}
          </div>
        </div>
      </div>

      <div className={Style.filePreviewContainer}>
        {activeFiles.map((file) => (
          <div key={file.id} className={Style.fileContainer}>
            {file.type.startsWith('image/') ? (
              <img src={URL.createObjectURL(file)} alt={`Preview of ${file.name}`} />
            ) : (
              <div className={Style.fileIcon}>
                <Icon size='100px' type='file_copy' />
              </div>
            )}
            <div className={Style.fileInfo}>
              <span className={Style.fileName}>{file.name}</span>
              <Icon className={Style.closeIcon} onClick={() => handleRemoveFile(file.id ?? '')} type='close' />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FileUpload
