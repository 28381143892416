import { FC, useMemo } from 'react'
import { useRecoilValueLoadable, useRecoilValue } from 'recoil'
import { Grid } from '@aurecon-creative-technologies/styleguide'
import { ResponsiveContainer, BarChart, CartesianGrid, Bar, XAxis, YAxis, Label, Tooltip, Legend } from 'recharts'

import { SurveyOverviewRange, SurveyOverviewTotal } from '../../store/AdminStore'
import { IChartData } from '../../api/admin/dashboard'
import { DashboardRangesEnum } from '../../enums/DashboardRangesEnum'

import Style from '../../styles/SurveyReport.module.sass'

interface IDashboardOverviewProps {
  chartData: IChartData[]
  dataLoading: boolean
}

export const DashboardOverview: FC<IDashboardOverviewProps> = (props) => {
  const surveyOverviewTotal = useRecoilValueLoadable(SurveyOverviewTotal)
  const surveyOverviewRange = useRecoilValue(SurveyOverviewRange)

  const { chartData, dataLoading } = props

  const total = useMemo(() => {
    if (surveyOverviewTotal.state !== 'hasValue') return 0

    return surveyOverviewTotal.contents
  }, [surveyOverviewTotal])

  const totalRange = useMemo(() => {
    if (!chartData) return 0
    return chartData.reduce((value, point) => point.total + value, 0)
  }, [chartData])

  return (
    <div className={Style.overview}>
      <Grid row gap={12}>
        <Grid item xs={12} cssClass={Style.heading}>
          <h2>Total Surveys</h2>
          {surveyOverviewRange === 'custom' && (
            <h3>
              {totalRange} submitted, {total} all
            </h3>
          )}
          {surveyOverviewRange === DashboardRangesEnum.DAY && (
            <h3>
              {totalRange} today, {total} all
            </h3>
          )}
          {surveyOverviewRange === DashboardRangesEnum.WEEK && (
            <h3>
              {totalRange} this week, {total} all
            </h3>
          )}
          {surveyOverviewRange === DashboardRangesEnum.MONTH && (
            <h3>
              {totalRange} this month, {total} all
            </h3>
          )}
        </Grid>

        {dataLoading && (
          <Grid item xs={12}>
            <p>Loading survey data...</p>
          </Grid>
        )}

        {!dataLoading && (
          <Grid item xs={12}>
            <ResponsiveContainer width={'100%'} height={450}>
              <BarChart data={chartData} margin={{ top: 5, right: 5, bottom: 0, left: 20 }}>
                <CartesianGrid strokeDasharray='2 2' />
                <XAxis dataKey='date' height={44}>
                  <Label value={surveyOverviewRange === 'day' ? 'Hour' : 'Day'} position='insideBottom' />
                </XAxis>
                <YAxis>
                  <Label value='Number of responses' angle={270} position='insideLeft' />
                </YAxis>
                <Tooltip labelFormatter={(text) => (surveyOverviewRange === 'day' ? text + ':00' : text)} />
                <Legend />
                <Bar
                  dataKey='total'
                  name='Surveys submitted'
                  stroke='#1F6997'
                  fill='#1F6997'
                  isAnimationActive={false}
                />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
