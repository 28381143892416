import { IChartData } from '../api/admin/dashboard'

const DAY_HOURS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]

export const mergeHours = (data: IChartData[] | null) => {
  return DAY_HOURS.map((hour) => {
    const hourData = data?.find((h) => h.hour === hour)

    return {
      date: `${hour}`,
      total: hourData ? hourData.total : 0,
    }
  })
}
